var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card',[(_vm.title)?_c('v-card-title',[_c('span',{staticClass:"text-h3"},[_vm._v(_vm._s(_vm.$t(_vm.title)))])]):_vm._e(),_c('v-card-text',[_c('v-container',[_c('v-row',[(_vm.authUser.isAdmin)?_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-select',{attrs:{"disabled":_vm.type == 'edit',"dense":"","label":_vm.$t('select_product_*'),"items":['oem', 'fleet'],"rules":[_vm.rules.required]},model:{value:(_vm.options.product),callback:function ($$v) {_vm.$set(_vm.options, "product", $$v)},expression:"options.product"}})],1):_vm._e(),(_vm.authUser.isAdmin && _vm.type !== 'edit')?_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.type == 'edit',"dense":"","loading":_vm.tenantLoading,"items":_vm.tenantLIST,"item-text":"name","item-value":"id","label":_vm.options.product == 'fleet'
                  ? _vm.$t('select_fleet_*')
                  : _vm.$t('select_tenant_*'),"return-object":"","rules":[_vm.rules.required]},on:{"change":_vm.checkTenant},model:{value:(_vm.selectTenant),callback:function ($$v) {_vm.selectTenant=$$v},expression:"selectTenant"}})],1):_vm._e(),(_vm.type == 'edit')?_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{attrs:{"disabled":_vm.type == 'edit',"dense":"","label":_vm.options.product == 'fleet'
                  ? _vm.$t('select_fleet_*')
                  : _vm.$t('select_tenant_*'),"rules":[_vm.rules.required]},model:{value:(_vm.options.tenant_name),callback:function ($$v) {_vm.$set(_vm.options, "tenant_name", $$v)},expression:"options.tenant_name"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-select',{attrs:{"dense":"","disabled":_vm.type == 'edit' || _vm.readOnly,"loading":_vm.isPart,"items":_vm.options.tenant_id || _vm.options.fleetId ? _vm.partList : [],"item-value":"id","item-text":"name","label":_vm.$t('parts_*'),"rules":[_vm.rules.required]},model:{value:(_vm.options.part),callback:function ($$v) {_vm.$set(_vm.options, "part", $$v)},expression:"options.part"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","disabled":_vm.readOnly,"label":_vm.$t('purchase_count_*'),"rules":[_vm.rules.number]},model:{value:(_vm.options.purchase_count),callback:function ($$v) {_vm.$set(_vm.options, "purchase_count", $$v)},expression:"options.purchase_count"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","disabled":_vm.readOnly,"label":_vm.$options.filters.currencyFormat(
                  _vm.$t('single_unit_price'),
                  _vm.authUser
                ),"rules":[_vm.rules.number]},model:{value:(_vm.options.stock_price),callback:function ($$v) {_vm.$set(_vm.options, "stock_price", $$v)},expression:"options.stock_price"}})],1)],1)],1)],1),_c('v-card-actions',[(!_vm.readOnly)?_c('small',[_vm._v(_vm._s(_vm.$t("indicates_required_field")))]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.closeForm()}}},[_vm._v(" Close ")]),(!_vm.readOnly)?_c('v-btn',{attrs:{"color":"primary","text":"","loading":_vm.loading},on:{"click":_vm.saveItem}},[_vm._v(" Save ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }