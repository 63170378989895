<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card>
      <v-card-title v-if="title">
        <span class="text-h3">{{ $t(title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-if="authUser.isAdmin"
              cols="6"
              sm="6"
            >
              <v-select
                v-model="options.product"
                :disabled="type == 'edit'"
                dense
                :label="$t('select_product_*')"
                :items="['oem', 'fleet']"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              v-if="authUser.isAdmin && type !== 'edit'"
              cols="6"
              sm="6"
            >
              <v-autocomplete
                v-model="selectTenant"
                :disabled="type == 'edit'"
                dense
                :loading="tenantLoading"
                :items="tenantLIST"
                item-text="name"
                item-value="id"
                :label="
                  options.product == 'fleet'
                    ? $t('select_fleet_*')
                    : $t('select_tenant_*')
                "
                return-object
                :rules="[rules.required]"
                @change="checkTenant"
              />
            </v-col>
            <v-col
              v-if="type == 'edit'"
              cols="6"
              sm="6"
            >
              <v-text-field
                v-model="options.tenant_name"
                :disabled="type == 'edit'"
                dense
                :label="
                  options.product == 'fleet'
                    ? $t('select_fleet_*')
                    : $t('select_tenant_*')
                "
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="6"
              sm="6"
            >
              <v-select
                v-model="options.part"
                dense
                :disabled="type == 'edit' || readOnly"
                :loading="isPart"
                :items="options.tenant_id || options.fleetId ? partList : []"
                item-value="id"
                item-text="name"
                :label="$t('parts_*')"
                :rules="[rules.required]"
              />
            </v-col>

            <v-col
              cols="6"
              sm="6"
            >
              <v-text-field
                v-model="options.purchase_count"
                dense
                :disabled="readOnly"
                :label="$t('purchase_count_*')"
                :rules="[rules.number]"
              />
            </v-col>
            <v-col
              cols="6"
              sm="6"
            >
              <v-text-field
                v-model="options.stock_price"
                dense
                :disabled="readOnly"
                :label="
                  $options.filters.currencyFormat(
                    $t('single_unit_price'),
                    authUser
                  )
                "
                :rules="[rules.number]"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small v-if="!readOnly">{{ $t("indicates_required_field") }}</small>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
        <v-btn
          v-if="!readOnly"
          color="primary"
          text
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  components: {},
  mixins: [validationRules],
  props: {
    type: {
      type: String,
      default() {
        return {
          time: null,
        };
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selectTenant: {},
      options: {
        tenant_id: null,
      },

      //
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantOptions",
      tenantsOEM: "tenantsManagement/getTenantsOEM",
      tenantsFLEET: "tenantsManagement/getTenantsFLEET",
      //
      isPart: "parts/getLoading",
      partList: "parts/getList",
      //
    }),
    tenantLIST() {
      if (this.options.product == "oem") {
        return this.tenantsOEM;
      } else if (this.options.product == "fleet") {
        return this.tenantsFLEET;
      } else {
        return [];
      }
    },
    title() {
      if (this.readOnly) {
        return "view";
      }
      if (this.type == "create") {
        return "add_new";
      } else if (this.type == "edit") {
        return "edit";
      } else {
        return "";
      }
    },
  },
  watch: {
    // "options.tenant_id": {
    //   handler: async function (val) {
    //     // this.checkTenant(val);
    //     if (this.type == "create") this.fetchDependents(val);
    //   },
    // },
  },
  async mounted() {
    if (this.type == "create") {
      if (this.authUser.isAdmin) {
        // this.fetchDependents(this.authUser.tenant_id);
      } else {
        this.options.tenant_id = this.authUser.tenant_id;
        this.fetchDependents(this.authUser.tenant_id);
      }
    }

    if (this.type == "edit") {
      this.options = { ...this.item };
      this.fetchDependents(this.options.tenant_id);
    }
  },
  methods: {
    saveItem() {
      if (this.$refs.form.validate()) {
        this.options.purchase_count = parseInt(this.options.purchase_count);
        this.options.stock_price = parseFloat(this.options.stock_price);

        if (this.type == "edit") {
          delete this.options.stock_count;
        }

        this.$emit("onsave", this.options);
      }
    },
    closeForm() {
      // this.$refs.form.reset();
      this.$emit("onclose");
    },

    async fetchDependents() {
      this.getPartsList();
    },
    async getPartsList() {
      await this.$store.dispatch("parts/list", this.options);
    },

    checkTenant(val) {
      delete this.options.fleetId;
      delete this.options.oem_b2b_ids;
      if (val.product == "oem") {
        this.options.tenant_id = val.id;
      } else if (val.product == "fleet") {
        this.options.fleetId = val.id;
        this.options.oem_b2b_ids = val.oem_b2b_ids;
        this.options.tenant_id = val.parent_tenant_ids[0];
      }
      this.fetchDependents();
    },

    //
  },
};
</script>
